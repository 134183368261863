const navigationItems = [
    {
      label: "Careers",
      children: [
        {
          label: "Global Careers",
          href: "/careers",
        },
        {
          label: "Our Brands",
          href: "/careers/our-brands-group",
          children: [
            {
              label: "Columbia",
              href: "/careers/our-brands-group/?tab-78baa3e833ecb8b09e4",
            },
            {
              label: "SOREL",
              href: "/careers/our-brands-group/?tab-9d37deaf387dfeb99f5",
            },
            {
              label: "Mountain Hardwear",
              href: "/careers/our-brands-group/?tab-7f9133205962d06a60c",
            },
            {
              label: "prAna",
              href: "/careers/our-brands-group/?tab-05cf9eb2b0103558eb1",
            }
          ]
        },
        {
          label: "Our Regions & Locations",
          //href: "/careers/regions-group/?tab-c40defabe206bb77568",
          children: [
            {
              label: "North America",
              href: "/careers/regions-group/north-america",
            },
            {
              label: "Asia",
              href: "/careers/regions-group/asia",
            },
            {
              label: "Europe",
              href: "/careers/regions-group/europe",
            }
          ]
        }
      ]
    },
    {
      label: "Our Company",
      children: [
        // {
        //   label: "About Us",
        //   href: "#"
        // },
        {
          label: "Inclusion & Diversity",
          href: "/our-company/inclusion-diversity"
        },
        {
          label: "Leadership",
          href: "/our-company/leadership"
        },
        {
          label: "Our History",
          href: "/our-company/our-history"
        },
        {
          label: "Strategy, Mission & Values",
          href: "/our-company/strategy-mission-values"
        },
        {
          label: "Wellbeing & Benefits",
          href: "/our-company/wellbeing-benefits"
        }
      ]
    },
  {
    label: "Corporate Responsibility",
    children: [
      {
        label: "Overview",
        href: "/corporate-responsibility-group/?tab-7924f1d2e71de065b6f="
      },
      {
        label: "Highlights",
        href: "/corporate-responsibility-group/?tab-bd5d2f27b7afd43e2d1="
      },
      {
        label: "Impact Report",
        href: "/corporate-responsibility/impact"
      },
      {
        label: "Empowering People",
        href: "/corporate-responsibility-group/?tab-6b450decc1cd295a70a="
      },
      {
        label: "Sustaining Places",
        href: "/corporate-responsibility-group/?tab-19017f87daea3a103ca="
      },
      {
        label: "Responsible Practices",
        href: "/corporate-responsibility-group/?tab-b57f0800d43fff2925c="
      }
    ]
  },
  {
    label: "News",
    children: [
      {
        label: "Press Releases",
        href: "https://investor.columbia.com/news-events/press-releases",
        isExternal: true
      },
      // {
      //   label: "Feature Stories",
      //   href: "#"
      // }
    ]
  },
  {
    label: "Investor Relations",
    href: "https://investor.columbia.com/",
    isExternal: true
  },
  {
    label: "Shop",
    children: [
      {
        label: "Columbia",
        href: "https://www.columbia.com",
        isExternal: true,
        logo: 'colLogo'
      },
      {
        label: "Mountain Hardwear",
        href: "https://www.mountainhardwear.com/",
        isExternal: true,
        logo: 'mhwLogo'
      },
      {
        label: "SOREL",
        href: "https://www.sorel.com/",
        isExternal: true,
        logo: 'sorLogo'
      },
      {
        label: "prAna",
        href: "https://www.prana.com/",
        isExternal: true,
        logo: 'praLogo'
      },
    ]
  }
];

export default navigationItems;
